import firebase from 'firebase'

var firebaseConfig = {
  apiKey: 'AIzaSyD7FB2h7fTnlgu9K_i1kVW1thfuAb2noq0',
  authDomain: 'jtask-apps.firebaseapp.com',
  databaseURL: 'https://jtask-apps.firebaseio.com',
  projectId: 'jtask-apps',
  storageBucket: 'jtask-apps.appspot.com',
  messagingSenderId: '275733006295',
  appId: '1:275733006295:web:00fb60d785f3e652e6ad92'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

const db = firebaseApp.firestore()

export { db, auth }
