<template>
  <div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__faster animate__slideInRight"
      leave-active-class="animate__animated animate__faster animate__slideOutRight"
    >
      <div
        class="navigation"
        v-if="isOpenNavigation">
        <button class="navigation__btn-close" @click="toggleNavigation('close')"><i class="material-icons">close</i></button>
        <div class="navigation__top">
          <span
            class="navigation__avatar"
            v-if="user">
            {{ userInitials }}
          </span>
          <h2>Welcome, {{ userFullName }}</h2>
        </div>

        <ul class="navigation__menu">
          <li class="divider-grey">Webapp</li>
          <li><router-link to="/pmp/lessons"><span class="material-icons icon">school</span><span class="text">PMP Course</span></router-link></li>
          <!-- <li>
            <router-link v-if="isCcmpPro" to="/ccmp-pro/tasks">
              <span class="material-icons icon">business</span>
              <span class="text">CCMP PRO</span>
            </router-link>
            <router-link v-if="!isCcmpPro" to="/ccmp-pro-overview">
              <span class="material-icons icon">business</span>
              <span class="text">CCMP PRO</span>
            </router-link>
          </li>
          <li>
            <router-link v-if="isCcmpPro" to="/ccmp-pro/templates">
              <span class="material-icons icon">article</span>
              <span class="text">CCMP Templates</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/account">
              <span class="material-icons icon">settings</span>
              <span class="text">Account Settings</span>
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="signOut()">
              <span class="material-icons icon">logout</span>
              <span class="text">Sign Out</span>
            </a>
          </li>

          <li class="divider-grey">General</li>
          <li><router-link to="/about">About jTask</router-link></li>
          <li><router-link to="/privacy">Privacy Policy</router-link></li>
          <li><router-link to="/legal">Legal</router-link></li>

          <li class="divider-grey">External Links</li>
          <li><a href="https://jtask.com/pmp-training/pmp-course-description" target="_blank" class="outbound-link">PMP Poster</a></li>
          <li><a href="https://www.pmi.org/certifications/project-management-pmp" target="_blank" class="outbound-link">PMI PMP</a></li>
          <li><a href="https://ccmp-webapp.jtask.com" target="_blank" class="outbound-link">jTask CCMP Webapp</a></li>
        </ul>

        <footer class="footer">
          <p class="copyright">Copyright 2021 © jTask, Inc. All rights reserved.</p>
        </footer>
      </div>
    </transition>

    <div class="page-wrapper">
      <header class="header">
        <a @click="onLogoClick" class="logo">
          <img src="@/assets/jtask-pmp-webapp-logo.svg" alt="jTask PMP Webapp Logo">
        </a>
        <button
          class="btn-open-nav"
          v-if="user && isUserVerified"
          @click="toggleNavigation('open')">
            <span class="material-icons icon">menu</span>
        </button>
      </header>

      <div class="content">
        <div v-if="loadingStatus" class="loading-animation">
          <span class="text">Loading ...</span>
        </div>

        <div v-show="!loadingStatus" class="content-inner">
          <transition name="fade">
            <router-view/>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from './store'
import { auth } from './firebase'
import firebase from 'firebase'

export default {
  name: 'app',
  store,
  components: { },

  data () {
    return {
      isOpenNavigation: false
    }
  },

  created () {

  },

  mounted () {
    this.setDocHeight()
    addEventListener('resize', this.setDocHeight)
    addEventListener('orientationchange', this.setDocHeight)
  },

  watch: {
    $route (to, from) {
      this.toggleNavigation('close')
    }
  },

  computed: {
    loadingStatus () {
      return this.$store.state.loadingStatus
    },

    user () {
      return this.$store.state.user
    },

    userFullName () {
      if (this.$store.state.user) {
        return this.$store.state.user.displayName
      }
      return null
    },

    userInitials () {
      if (this.userFullName) {
        const fullName = this.userFullName
        const names = fullName.toUpperCase().split(' ')
        const initials = names[0].charAt(0) + names[1].charAt(0)
        return initials
      }

      return null
    },

    isUserVerified () {
      return this.user && this.user.emailVerified
    },

    homepage () {
      return this.$store.state.account.homepage
    },

    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    }
  },

  methods: {
    toggleNavigation (action) {
      if (action === 'open') {
        this.isOpenNavigation = true
        document.body.classList.add('is-open-nav')
      }

      if (action === 'close') {
        this.isOpenNavigation = false
        document.body.classList.remove('is-open-nav')
      }
    },

    setDocHeight () {
      const windowHeight = window.innerHeight
      const deviceOrientation = windowHeight > 400 ? 'vertical' : 'landscape'

      if (deviceOrientation === 'vertical') {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
      } else {
        document.documentElement.style.setProperty('--vh', '100%')
      }

      console.log('setDocHeight: ', windowHeight)
    },

    signOut () {
      auth
        .signOut()
        .then(() => {
          this.$router.replace({
            name: 'welcome'
          })
        })
    },

    onLogoClick () {
      if (!this.isUserVerified) {
        this.checkForEmailVerificationUpdate(this)
      } else {
        this.$router.push('/home')
      }
    },

    checkForEmailVerificationUpdate: async (that) => {
      const currentUser = await firebase.getCurrentUser()
      if (currentUser.emailVerified) {
        that.$store.dispatch('setCurrentUser', currentUser)
        that.$router.push('/')
      }
    }
  }

}
</script>

<style lang="scss">
  @import '@/styles/_variables.scss';

  // Main Styles
  // =====================================================================

  @function vh($quantity) {
    @return calc((var(--vh, 1vh) * #{$quantity}));
  }

  html {
    margin: 0 auto;
  }

  html, body {
    height: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: $color-text;

    &.is-open-nav {
      overflow: hidden;
    }
  }

  p, ul {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 17px;
  }

  #app {
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }

  .spacer-20 {
    display: block;
    height: 20px;
  }

  .spacer-50 {
    display: block;
    height: 50px;
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    height: vh(100);
  }

  .content {
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }

  .content-inner {
    height: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    background: #F8F8F8;
    padding: 0 10px;
  }

  .logo {
    display: inline-block;
    padding: 10px 0;
    cursor: pointer;
  }

  .footer {
    width: 100%;
    max-width: 800px;
    min-height: 40px;
    text-align: center;
    background: #2D2D2D;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;

    .copyright {
      font-size: 12px;
      margin: 0;
    }
  }

  .navigation {
    position: absolute;
    width: 100%;
    max-width: 800px;
    height: 100%;
    z-index: 20;
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  .navigation__top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  .navigation__avatar {
    display: block;
    width: 45px;
    height: 45px;
    background: $jtask-blue-dark;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
    font-size: 20px;
  }

  .navigation__btn-close {
    background: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 5px;
    cursor: pointer;
    z-index: 10;

    i {
      font-size: 30px;
    }

    &:hover {
      i {
        color: $orange-dark;
      }
    }
  }

  .navigation__menu {
    height: 100%;
    padding: 0 0 40px 0;
    margin: 0;
    list-style: none;
    overflow: scroll;

    li {
      position: relative;

      .icon {
        position: absolute;
        top: 15px;
        color: #393939;
      }

      .text {
        padding-left: 35px;
      }

      &.divider-grey {
        padding: 5px 10px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 15px;
        border-bottom: 1px solid #cfcfcf;
        color: #7b7b7b;
      }

      &.divider-blue {
        height: 2px;
        background: $jtask-blue-dark;
        border-bottom: 1px solid $jtask-blue-dark;
      }

      &:first-child a {
        border-top: 1px solid #cfcfcf;
      }

    }

    a {
      display: block;
      font-size: 16px;
      text-decoration: none;
      color: $color-text;
      padding: 16px 12px;
      background: #F8F8F8;
      border-bottom: 1px solid #cfcfcf;

      &:hover {
        color: $jtask-blue-dark;
        background: #e6e6e6;
      }

      &.router-link-active {
        color: $jtask-blue-dark;
        background: #e6e6e6;

        .icon {
          color: $jtask-blue-dark
        }
      }

      &:after {
        font-family: 'Material Icons';
        content: "keyboard_arrow_right";
        font-size: 24px;
        position: absolute;
        right: 10px;
        margin-top: -5px;
      }

      &.outbound-link {
        &:after {
          font-family: 'Material Icons';
          content: "open_in_new";
          font-size: 20px;
          position: absoliute;
          right: 10px;
        }
      }

      .icon-home {
        font-size: 20px;
        line-height: 0;
        position: relative;
        top: 3px;
        left: 5px;
      }
    }
  }

  .user-avatar {
    width: 35px;
    height: 35px;
    background: $jtask-blue-dark;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
  }

  .btn-open-nav {
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:hover {
      & > .icon {
        color: $orange-dark;
      }
    }

    & > .icon {
      font-size: 33px;
    }
  }

  .page-title {
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    margin: 20px;

    &.with-subtitle {
      margin-bottom: 5px;
    }
  }

  .page-subtitle {
    text-align: center;
    margin-bottom: 30px;
    color: #6f6f6f;
  }

  .page-description {
    text-align: center;
  }

  .page-container {
    padding: 20px;
    overflow: auto;
    height: 100%;
  }

  .page-container-alt {
    padding: 20px;
    overflow: auto;
    height: calc(100% - 40px);
  }

  .page-container-simple {
    padding: 20px;
    overflow: auto;
    height: calc(100% - 110px);
  }

  .button-primary {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    height: 50px;
    width: 100%;
    background: $jtask-blue-dark;
    border: none;
    margin-bottom: 30px;
  }

   .button-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    background: $color-button-primary;

    i {
      color: #fff;
      margin-right: 10px;
    }

    &.full-width {
      width: 100%;
    }
  }

  .button-tertiary {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $color-text;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    background: $color-button-secondary;

    i {
      color: $color-text;
      margin-right: 10px;
    }

    &.full-width {
      width: 100%;
    }
  }

  label {
    font-size: 15px;
    line-height: 1.5;
  }

  input {
    font-size: 14px;
    height: 45px;
    padding: 0 20px;
    width: calc(100% - 40px);
    background: $blue-light;
    border: none;
    margin-bottom: 8px;

    &:disabled {
      color: #585858;
      cursor: not-allowed;    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-size: 14px;
    padding: 0 15px;
    width: 100%;
    height: 45px;
    background: $blue-light;
    border: none;
    margin-bottom: 5px;
    display: block;

    &:disabled {
      color: #585858;
      opacity: 1;
      cursor: not-allowed;
    }
  }

  .select-wrapper {
    position: relative;

    &:after {
      font-family: 'Material Icons';
      content: "keyboard_arrow_down";
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }

  .markdown-container {
    p, a, ul, span {
      font-size: 14px;
    }

    a {
      color: $jtask-blue-dark;
      text-decoration: none;
    }

    ul, ol {
      padding-left: 20px;
    }

    li {
      margin: 5px 0;
    }
  }

  .alert {
    font-size: 15px;
    line-height: 1.4;
    color: #fff;
    padding: 15px;
    margin-bottom: 20px;

    &.alert-error {
      background: $color-error;
    }

    &.alert-success {
      background: $color-success;
    }
  }

  [data-lesson-id="1"],
  [data-class-id="1"] {
    background: $orange-dark;
  }

  [data-lesson-id="2"],
  [data-class-id="2"] {
    background: $jtask-blue-dark;
  }

  [data-lesson-id="3"],
  [data-class-id="3"] {
    background: $blue-dark;
  }

  [data-lesson-id="4"],
  [data-class-id="4"] {
    background: $green-dark;
  }

  [data-lesson-id="5"] {
    background: $red-dark;
  }

  .loading-animation {
    padding: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      margin-bottom: 15px;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 35px;
      height: 35px;
      border: 3px solid rgba(255,255,255,.3);
      border-radius: 50%;
      border-top-color: $jtask-blue-dark;
      border-right-color: $jtask-blue-dark;
      border-bottom-color: $jtask-blue-dark;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }
  }

  @media screen and (min-height: 800px) {
    #app {
      box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    }
  }

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

  // Transitions
  // =====================================================================

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
