<template>
  <div class="page-lessons">
    <h1 class="page-title">Project Management Institute <br> PMP® Course Description</h1>

    <!-- <div class="search-container">
      <input type="search" class="search-input" placeholder="Find objectives, enablers, deliverables ..." />
      <button class="search-button"><i class="material-icons">search</i></button>
    </div> -->

    <ul class="lessons-list">
      <li
        v-for="lesson in lessons" :key="lesson.id"
        class="lesson-item"
        :data-lesson-id="lesson.id">
        <router-link :to="{ name: 'pmp-lesson', params: { id: lesson.id }}" class="lesson-link">
          <span class="lesson-number">Lesson {{ lesson.id }}</span>
          <span class="lesson-name">{{ lesson.title }}</span>
        </router-link>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'PmpLessons',

  computed: {
    lessons () {
      return this.$store.state.pmpData.lessons
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lessons {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .search-container {
    padding: 20px 10px;
  }

  .search-input {
    width: 100%;
    height: 50px;
    background: #F8F8F8;
    border: 1px solid #DDDDDD;
    padding: 15px 25px;
    font-size: 14px;
  }

  .search-button {
    border: none;
    position: absolute;
    right: 10px;
    background: transparent;
    height: 50px;
    width: 50px;
  }

  .lessons-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    list-style: none;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
  }

  .lesson-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    margin-bottom: 1px;
    flex-grow: 1;
    justify-content: center;
  }

  .lesson-link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    text-decoration: none;

    &:after {
      font-family: 'Material Icons';
      content: "keyboard_arrow_right";
      color: #FFFFFF;
      font-size: 30px;
      position: absolute;
      right: 10px;
    }
  }

  .lesson-number {
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
  }

  .lesson-title {
    font-size: 16px;
    line-height: 1.4;
  }
</style>
