import { createStore } from 'vuex'
import { db } from './../firebase'
import firebase from 'firebase'

const store = createStore({
  state () {
    return {
      user: null,
      account: {
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        homepage: ''
      },
      loadingStatus: false,
      pmpData: {
        lessons: [],
        objectives: []
      }
    }
  },

  mutations: {
    loadPmpData (state, newData) {
      state.pmpData.lessons = newData.lessons
      state.pmpData.objectives = newData.objectives
    },

    loadingStatus (state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },

    setUser (state, data) {
      if (data) {
        state.user = {
          id: data.id,
          displayName: data.displayName,
          email: data.email,
          emailVerified: data.emailVerified,
          idToken: data.idToken
        }
        state.account.email = data.email
        state.account.firstName = data.firstName
        state.account.lastName = data.lastName
        state.account.company = data.company
        state.account.homepage = data.homepage
      } else {
        state.user = null
        state.account.email = ''
        state.account.firstName = ''
        state.account.lastName = ''
        state.account.company = ''
        state.account.homepage = ''
      }
    },

    setHomepage (state, data) {
      state.account.homepage = data
    }
  },

  actions: {
    loadPmpDataFromServer ({ commit }) {
      commit('loadingStatus', true)

      fetch(process.env.VUE_APP_FIREBASE_URL + '/pmp-data', {
        headers: {
          Authorization: `Bearer ${this.state.user.idToken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          commit('loadPmpData', data)
          commit('loadingStatus', false)
        })
        .catch((error) => {
          console.error(error.statusText)
        })
    },

    updateHomepage ({ commit }, homepage) {
      db.collection('users').doc(this.state.user.id).update({
        homepage: homepage
      }).then(function () {
        commit('setHomepage', homepage)
      }).catch(function (error) {
        console.error('Error updating homepage: ', error)
      })
    },

    reloadCurrentUser: async function ({ commit }) {
      const currentUser = await firebase.getCurrentUser()
      this.dispatch('setCurrentUser', currentUser)
    },

    setCurrentUser ({ commit }, user) {
      if (user) {
        db.collection('users').doc(user.uid).get().then((doc) => {
          if (doc.exists) {
            const userData = doc.data()

            user.getIdToken().then((idToken) => {
              commit('setUser', {
                id: user.uid,
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                idToken: idToken,
                firstName: userData.firstName,
                lastName: userData.lastName,
                company: userData.company,
                homepage: userData.homepage
              })

              commit('loadingStatus', false)

              // Load data
              if (user.emailVerified) {
                this.dispatch('loadPmpDataFromServer')
              }
            })
          } else {
            console.error('Could not get user data')
          }
        }).catch(function (error) {
          console.error('Error getting document:', error)
        })
      } else {
        commit('setUser', null)
        commit('loadingStatus', false)
      }
    }
  }
})

export default store
