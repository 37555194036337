<template>
  <div class="page-home">
  </div>
</template>

<script>

export default {
  name: 'home',

  watch: {
    homepage: function (val) {
      this.changeHomepage()
    }
  },

  created () {
    this.changeHomepage()
  },

  computed: {
    homepage () {
      return this.$store.state.account.homepage
    }
  },

  methods: {
    changeHomepage () {
      this.$router.replace({ name: 'pmp-lessons' })

      // if (this.homepage === 'ccmp') {
      //   this.$router.replace({ name: 'ccmp-lessons' })
      // }

      // if (this.homepage === 'pmp') {
      //   this.$router.replace({ name: 'pmp-lessons' })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

</style>
