<template>
  <div class="page-container">
    <div v-if="error" class="alert alert-error">{{ error }}</div>
    <div v-if="success" class="alert alert-success">{{ success }}</div>

    <h1 class="page-title">Please verify your email first</h1>
    <p>Before you can start using the application, please look for the verification email in your inbox and follow the link to activate your jTask PMP Webapp account.</p>
    <span class="spacer-50"></span>
    <h2>Did you not receive an email?</h2>
    <p>If you did not receive a verification email you can <a href="#" @click.prevent="resendEmailVerification()">click here to resend it</a> or you can contact us at <a href="mailto:support@jtask.com">support@jtask.com</a></p>
    <span class="spacer-50"></span>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      timer: null,
      error: null,
      success: null
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    }
  },

  mounted () {
    // Check every 10 seconds if the user has verified his email address
    // this.timer = setInterval(() => {
    //   this.checkEmailVerificationStatus(this)
    // }, 10000)
  },

  methods: {
    async checkEmailVerificationStatus () {
      const currentUser = await firebase.getCurrentUser()
      if (currentUser.emailVerified) {
        this.$store.dispatch('setCurrentUser', currentUser)
        clearInterval(this.timer)
        this.$router.push('/')
      }
    },

    async resendEmailVerification () {
      this.error = null
      this.success = null

      const currentUser = await firebase.getCurrentUser()
      currentUser.sendEmailVerification({
        url: process.env.VUE_APP_URL + '/',
        handleCodeInApp: false
      }).then(() => {
        this.success = 'Email verification sucessfully resent'
      }).catch(() => {
        this.error = 'Could not send email verification. Please contact support@jtask.com'
      })
    }
  }
}

</script>

<style lang="scss">

</style>
