import { createRouter, createWebHashHistory } from 'vue-router'
import firebase from 'firebase'

import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import EmailVerification from '../views/EmailVerification.vue'
import Welcome from '../views/Welcome.vue'
import Account from '../views/Account.vue'
import Privacy from '../views/Privacy.vue'
import Legal from '../views/Legal.vue'
import About from '../views/About.vue'

import PmpLessons from '../views/PmpLessons.vue'

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: { hideForAuth: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { hideForAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { hideForAuth: true }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: { hideForAuth: true }
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: EmailVerification,
    meta: { requiresAuth: true }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    meta: { requiresAuth: true }
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true }
  },

  // PMP Routes
  {
    path: '/pmp/lessons',
    name: 'pmp-lessons',
    component: PmpLessons,
    meta: { requiresAuth: true }
  },
  {
    path: '/pmp/lesson/:id',
    name: 'pmp-lesson',
    props: true,
    component: () => import('../views/PmpLesson.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pmp/objective/:id',
    name: 'pmp-objective',
    props: true,
    component: () => import('../views/PmpObjective.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Handle authentications
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const hideForAuth = to.matched.some(record => record.meta.hideForAuth)

  if (hideForAuth) {
    if (hideForAuth && await firebase.getCurrentUser()) {
      next('home')
    } else {
      next()
    }
  }

  if (requiresAuth) {
    const currentUser = await firebase.getCurrentUser()
    if (requiresAuth && !currentUser) {
      next('/')
    } else {
      // Check if email is verified
      if (currentUser.emailVerified) {
        next()
      } else {
        if (to.name !== 'email-verification') {
          next('email-verification')
        } else {
          next()
        }
      }
    }
  }
})

export default router
