import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import 'animate.css'

auth.onAuthStateChanged(user => {
  store.dispatch('setCurrentUser', user)
})

createApp(App)
  .use(router)
  .use(store)
  .mount('#app')
